
  export const style={
    inpuStyle:{
      colorInput:"#268c6e"
    },
    url:{
      urlClient:"https://hubsocial.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://s3.amazonaws.com/timcoo-geral/Logos/logoHubSocial%403x.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: false,
       autoRegister: false,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Noz/Nozes"
    },
    teamName:{
      name:"hubsocial"
    },
    hasToken: false,
    hostHeader: "hubsocial"
  }
